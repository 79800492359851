exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-about-company-tsx": () => import("./../../../src/pages/about/aboutCompany.tsx" /* webpackChunkName: "component---src-pages-about-about-company-tsx" */),
  "component---src-pages-about-history-tsx": () => import("./../../../src/pages/about/history.tsx" /* webpackChunkName: "component---src-pages-about-history-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-about-team-tsx": () => import("./../../../src/pages/about/team.tsx" /* webpackChunkName: "component---src-pages-about-team-tsx" */),
  "component---src-pages-careers-apply-for-job-tsx": () => import("./../../../src/pages/careers/ApplyForJob.tsx" /* webpackChunkName: "component---src-pages-careers-apply-for-job-tsx" */),
  "component---src-pages-careers-index-tsx": () => import("./../../../src/pages/careers/index.tsx" /* webpackChunkName: "component---src-pages-careers-index-tsx" */),
  "component---src-pages-contact-contact-us-tsx": () => import("./../../../src/pages/contact/contactUs.tsx" /* webpackChunkName: "component---src-pages-contact-contact-us-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-home-page-carriers-carriers-tsx": () => import("./../../../src/pages/home-page/carriers/Carriers.tsx" /* webpackChunkName: "component---src-pages-home-page-carriers-carriers-tsx" */),
  "component---src-pages-home-page-case-studies-case-studies-tsx": () => import("./../../../src/pages/home-page/case-studies/CaseStudies.tsx" /* webpackChunkName: "component---src-pages-home-page-case-studies-case-studies-tsx" */),
  "component---src-pages-home-page-cube-grid-tsx": () => import("./../../../src/pages/home-page/CubeGrid.tsx" /* webpackChunkName: "component---src-pages-home-page-cube-grid-tsx" */),
  "component---src-pages-home-page-hero-hero-home-tsx": () => import("./../../../src/pages/home-page/hero/HeroHome.tsx" /* webpackChunkName: "component---src-pages-home-page-hero-hero-home-tsx" */),
  "component---src-pages-home-page-home-page-tsx": () => import("./../../../src/pages/home-page/HomePage.tsx" /* webpackChunkName: "component---src-pages-home-page-home-page-tsx" */),
  "component---src-pages-home-page-our-experise-our-expertise-home-tsx": () => import("./../../../src/pages/home-page/our-experise/OurExpertiseHome.tsx" /* webpackChunkName: "component---src-pages-home-page-our-experise-our-expertise-home-tsx" */),
  "component---src-pages-home-page-rain-animation-rain-tsx": () => import("./../../../src/pages/home-page/rain-animation/Rain.tsx" /* webpackChunkName: "component---src-pages-home-page-rain-animation-rain-tsx" */),
  "component---src-pages-home-page-tech-tech-tsx": () => import("./../../../src/pages/home-page/tech/Tech.tsx" /* webpackChunkName: "component---src-pages-home-page-tech-tech-tsx" */),
  "component---src-pages-home-page-testimonials-testimonialss-tsx": () => import("./../../../src/pages/home-page/testimonials/Testimonialss.tsx" /* webpackChunkName: "component---src-pages-home-page-testimonials-testimonialss-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-expertise-expertises-desktop-expertises-tsx": () => import("./../../../src/pages/our-expertise/expertises/desktopExpertises.tsx" /* webpackChunkName: "component---src-pages-our-expertise-expertises-desktop-expertises-tsx" */),
  "component---src-pages-our-expertise-expertises-index-tsx": () => import("./../../../src/pages/our-expertise/expertises/index.tsx" /* webpackChunkName: "component---src-pages-our-expertise-expertises-index-tsx" */),
  "component---src-pages-our-expertise-expertises-mobile-expertises-tsx": () => import("./../../../src/pages/our-expertise/expertises/mobileExpertises.tsx" /* webpackChunkName: "component---src-pages-our-expertise-expertises-mobile-expertises-tsx" */),
  "component---src-pages-our-expertise-index-tsx": () => import("./../../../src/pages/our-expertise/index.tsx" /* webpackChunkName: "component---src-pages-our-expertise-index-tsx" */),
  "component---src-pages-portfolio-index-tsx": () => import("./../../../src/pages/portfolio/index.tsx" /* webpackChunkName: "component---src-pages-portfolio-index-tsx" */),
  "component---src-pages-portfolio-partner-projects-tsx": () => import("./../../../src/pages/portfolio/PartnerProjects.tsx" /* webpackChunkName: "component---src-pages-portfolio-partner-projects-tsx" */),
  "component---src-pages-portfolio-portfolio-section-tsx": () => import("./../../../src/pages/portfolio/PortfolioSection.tsx" /* webpackChunkName: "component---src-pages-portfolio-portfolio-section-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-prose-how-challenge-tsx": () => import("./../../../src/pages/prose/howChallenge.tsx" /* webpackChunkName: "component---src-pages-prose-how-challenge-tsx" */),
  "component---src-pages-prose-index-tsx": () => import("./../../../src/pages/prose/index.tsx" /* webpackChunkName: "component---src-pages-prose-index-tsx" */),
  "component---src-pages-prose-used-technologies-tsx": () => import("./../../../src/pages/prose/usedTechnologies.tsx" /* webpackChunkName: "component---src-pages-prose-used-technologies-tsx" */),
  "component---src-pages-prose-visit-pro-se-tsx": () => import("./../../../src/pages/prose/visitProSe.tsx" /* webpackChunkName: "component---src-pages-prose-visit-pro-se-tsx" */),
  "component---src-pages-prose-what-challenge-tsx": () => import("./../../../src/pages/prose/whatChallenge.tsx" /* webpackChunkName: "component---src-pages-prose-what-challenge-tsx" */),
  "component---src-pages-team-index-tsx": () => import("./../../../src/pages/team/index.tsx" /* webpackChunkName: "component---src-pages-team-index-tsx" */),
  "component---src-pages-team-team-members-tsx": () => import("./../../../src/pages/team/teamMembers.tsx" /* webpackChunkName: "component---src-pages-team-team-members-tsx" */)
}

